<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto mt-4 mb-4 break-search"
          label="Procurar"
          hide-details
          single-line
          style="max-width: 250px"
        />
        <v-data-table
          :headers="headers"
          :items="icoAnalitico"
          :loading="loadingIcoAnalitico"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.variavel_descricao`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota) }">
              {{ item.variavel_descricao }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano2`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano2) }">
              {{ item.variacao_ano2 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano2`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano2) }">
              {{ item.nota_ano2 }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano3`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano3) }">
              {{ item.variacao_ano3 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano3`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano3) }">
              {{ item.nota_ano3 }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano4`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano4) }">
              {{ item.variacao_ano4 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano4`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano4) }">
              {{ item.nota_ano4 }}
            </span>
          </template>
          <template v-slot:[`item.nota`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota) }">
              {{ item.nota }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano1`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano1"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno1)"
              >
                <div>{{ item.valor_bdgd_ano1 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno1 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano1 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano2`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano2"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno2)"
              >
                <div>{{ item.valor_bdgd_ano2 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno2 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano2 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano3`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano3"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno3)"
              >
                <div>{{ item.valor_bdgd_ano3 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno3 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano3 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano4`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano4"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno4)"
              >
                <div>{{ item.valor_bdgd_ano4 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno4 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano4 | parseNumberToFloatBR }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-circular
          v-if="loadingIcoAnalitico"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <div v-else>
          <status-indicador-ico-timeline
            :ref="`timelineIndicador${indicador}`"
            :indicador="indicador"
            :bdgdVersao="bdgdVersao"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';
import StatusIndicadorIcoTimeline from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIcoTimeline';
import iqBdgdMixins from './iqBdgdMixins';

export default {
  name: 'StatusIndicadorIco',
  mixins: [iqBdgdMixins],
  components: {
    StatusIndicadorIcoTimeline
  },
  props: {
    indicador: {
      type: String,
      required: true
    },
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    editavel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingIcoAnalitico: false,
    loadingSalvarVariavel: false,
    search: null,
    icoAnalitico: [],
    valorAtualizado: null,
    observacao: null
  }),
  mounted() {
    this.getIcoAnalitico();
  },
  computed: {
    headers() {
      const descricao = `${this.indicador} :: ${
        this.iqBdgdSiglas[this.indicador]
      }`;
      return [
        {
          text: descricao,
          value: 'variavel_descricao'
        },
        {
          text: this.bdgdAno1,
          value: 'valor_bdgd_ano1',
          align: 'end'
        },
        {
          text: this.bdgdAno2,
          value: 'valor_bdgd_ano2',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano2',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano2',
          align: 'end'
        },
        {
          text: this.bdgdAno3,
          value: 'valor_bdgd_ano3',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano3',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano3',
          align: 'end'
        },
        {
          text: this.bdgdAno4,
          value: 'valor_bdgd_ano4',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano4',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano4',
          align: 'end'
        },
        {
          text: 'Nota Final',
          value: 'nota',
          align: 'center'
        }
      ];
    },
    bdgdAno1() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano1']
        : 0;
    },
    bdgdAno2() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano2']
        : 0;
    },
    bdgdAno3() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano3']
        : 0;
    },
    bdgdAno4() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano4']
        : 0;
    }
  },
  methods: {
    getIcoAnalitico() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return false;
      this.loadingIcoAnalitico = true;
      IqBdgdService.getIcoAnalitico(this.bdgdVersao.id, this.indicador)
        .then((response) => (this.icoAnalitico = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados analíticos do indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingIcoAnalitico = false));
    },
    salvarVariavel(variavelId, bdgdAno) {
      this.loadingSalvarVariavel = true;
      const data = {
        indicador: this.indicador,
        variavel_id: variavelId,
        ano_bdgd: bdgdAno,
        valor: this.valorAtualizado,
        observacao: this.observacao
      };
      IqBdgdService.salvarVariavelIco(data)
        .then(() => {
          this.$toast.success(
            `Novo valor para o indicador ${this.indicador} salvo com sucesso.`,
            '',
            {
              position: 'topRight'
            }
          );
          this.observacao = null;
          this.getIcoAnalitico();
          const refTimeline = this.$refs[`timelineIndicador${this.indicador}`];
          refTimeline.getHistoricoStatusPorIndicador();
          this.$emit('statusIndicadorAtualizado', data);
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao salvar o novo valor para o indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingSalvarVariavel = false));
    }
  }
};
</script>
