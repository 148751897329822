import APIService from './APIService';

export default {
  getSiglas() {
    return APIService.apiCall().get(
      '/iq-bdgd/get-siglas'
    );
  },
  getNotas(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-notas/${bdgdVersaoId}`
    );
  },
  getIcoAnalitico(bdgdVersaoId, indicador) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-ico-analitico/${bdgdVersaoId}/${indicador}`
    );
  },
  getIcbAnalitico(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-icb-analitico/${bdgdVersaoId}`
    );
  },
  getIdbAnalitico(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-idb-analitico/${bdgdVersaoId}`
    );
  },
  getIptbAnalitico(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-iptb-analitico/${bdgdVersaoId}`
    );
  },
  getIebAnalitico(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-ieb-analitico/${bdgdVersaoId}`
    );
  },
  getIespAnalitico(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-iesp-analitico/${bdgdVersaoId}`
    );
  },
  getStatusPossiveisPorIndicador(indicador) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-status-possiveis-por-indicador/${indicador}`
    );
  },
  getStatusAtualPorIndicador(bdgdVersaoId, indicador) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-status-atual-por-indicador/${bdgdVersaoId}/${indicador}`
    );
  },
  getHistoricoStatusPorIndicador(bdgdVersaoId, indicador) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-historico-status-por-indicador/${bdgdVersaoId}/${indicador}`
    );
  },
  getHistoricoNumeroErrosIpos(bdgdVersaoId) {
    return APIService.apiCall().get(
      `/iq-bdgd/get-historico-numero-erros-ipos/${bdgdVersaoId}`
    );
  },
  salvarStatusIndicador(postData) {
    return APIService.apiCall().post(
      '/iq-bdgd/salvar-status-indicador',
      JSON.stringify(postData)
    );
  },
  salvarVariavelIco(postData) {
    return APIService.apiCall().post(
      '/iq-bdgd/salvar-variavel-ico',
      JSON.stringify(postData)
    );
  },
  salvarNumeroErrosIpos(postData) {
    return APIService.apiCall().post(
      '/iq-bdgd/salvar-numero-erros-ipos',
      JSON.stringify(postData)
    );
  }
};
